<template>
  <div class="sidebar-left">
    <div>
      <div class="sidebar-left-logo">
        <img src="/imgs/logo.png" alt="" />
      </div>
      <div class="sidebar-left-menu">
        <ul>
          <template v-for="item in menuItems">
            <li v-if="isShow(item.roles, item.name)" :key="item.id">
              <a v-if="item.id === 3" :href='item.href' target="_blank" class="pdkt_info">
                <ph-info :size="24" /> {{ item.name }}
              </a>
              <router-link
                v-else
                :to="`${item.href}`"
                class="text-xs"
                :class="{ active: item.id === 0, 'pointer-none opacity-05': item.disable, 'd-none': item.hidden }"
              >
                <div v-if="item.icon.status" class="icon">
                  <div v-if="item.icon.badge" class="badge">12</div>
                  <component
                    v-if="item.icon.type !== 'custom'"
                    :is="item.icon.name"
                  />
                  <v-icon v-else :icon="['fac', `${item.icon.name}`]" />
                </div>
                {{ item.name }}
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="sidebar-left-profile">
      <div class="item mb-4">
        <span>Tutorial</span>
        <a class="icon" target="_blank" href="https://www.figma.com/proto/1matShzgDHC8DnLY9UcAq1/Kurzanleitung---Katalogwand-(Final-Version)?page-id=0%3A1&node-id=2%3A11326&viewport=1396%2C3076%2C0.08&scaling=contain&starting-point-node-id=2%3A11326">
          <img src="@/assets/icons/tutorial.svg" alt="">
        </a>
      </div>
      <div class="item mb-4">
        <span>Melden</span>
        <a class="icon" :href="`mailto:mdm@einrichtungspartnerring.com?subject=Fehlermeldung Katalogwand - ${currentName}&body=URL: ${currentUrl}%0D%0A%0D%0A Bitte kommentieren Sie den Fehler. Dies erleichtert uns die Bearbeitung. Vielen Dank!`">
          <ph-question class="" :size="24" />
        </a>
      </div>
      <div
        v-outside="
          () => {
            showUserMenu = false;
          }
        "
        @click="fetchCompanies"
        class="item"
      >
        <span>Profil</span>
        <div class="icon">
          <ph-user class="" :size="24" />
        </div>
      </div>
      <div v-if="showUserMenu" class="sidebar-left-profile-menu">
        <div class="side-container">
          <div class="d-flex w-100 justify-content-between mb-4">
            <h4 class="bold-text mt-1" style="text-transform: uppercase;margin-right: 2em;">FirmenzugehörigKEit</h4>
            <div class="d-flex pl-4 align-self-center">
              <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.87549 4.375L12.4998 7L9.87549 9.625" stroke="#EE7F00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.5 7H12.4982" stroke="#EE7F00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.5 12.5H2C1.86739 12.5 1.74021 12.4473 1.64645 12.3536C1.55268 12.2598 1.5 12.1326 1.5 12V2C1.5 1.86739 1.55268 1.74021 1.64645 1.64645C1.74021 1.55268 1.86739 1.5 2 1.5H5.5" stroke="#EE7F00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <a @click.prevent="onLogout()" href="#">Abmelden </a>
            </div>
          </div>
          <loading v-if="loading" :size="'sm'"/>
          <div v-else class="w-100">
            <div v-for="(company, index) in getCompanies" :key="index" class="d-flex mb-4" :class="{ 'line': index }">
              <div class="d-flex w-100 justify-content-between" :class="index ? 'mt-4': 'mt-2'">
                <span class="bold-text ms-2">{{company.companyId}} - {{ `${company.zr_nummer}${company.zr_nummer?' -':''}` }} {{ company.name }}</span>
                <button
                  class="btn btn-primary"
                  style="max-height:40px"
                  :disabled="company.companyId===currentId"
                  @click="onLogin(company.companyId)">Auswählen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PhGear,
  PhUser,
  PhBellSimple,
  PhDotsNine,
  PhCircleWavyCheck,
  PhQuestion,
  PhInfo
} from 'phosphor-vue'
import outside from '@/directives/outside.js'
import { mapGetters, mapMutations } from 'vuex'
import loading from '@/components/common/Loading'
export default {
  components: {
    gear: PhGear,
    bell: PhBellSimple,
    dots: PhDotsNine,
    circleVerify: PhCircleWavyCheck,
    PhInfo,
    PhQuestion,
    PhUser,
    loading
  },
  directives: {
    outside
  },
  data () {
    return {
      showUserMenu: false,
      loading: false,
      currentUrl: window.location.href,
      currentName: this.$msal.data.user.name,
      menuItems: [
        {
          id: 0,
          name: 'Verkauf',
          href: '/seller',
          roles: ['isSellerUser'],
          icon: {
            status: true,
            name: 'ein',
            type: 'custom'
          }
        },
        {
          id: 1,
          name: 'Einkauf',
          href: '/buyer',
          roles: ['isPurchaserUser'],
          icon: {
            status: true,
            name: 'verk',
            type: 'custom'
          }
        },
        {
          disable: true,
          id: 2,
          hidden: true,
          name: 'Rabatte',
          href: '/rabatte',
          roles: ['isRabatteUser'],
          icon: {
            status: true,
            name: 'circleVerify',
            type: 'ph'
          }
        },
        {
          id: 3,
          name: 'Produkt Info',
          href: process.env.VUE_APP_PRODUCT_INFO_URL,
          roles: ['isAppAdminUser', 'isProductInfoUser']
        },
        {
          id: 4,
          name: 'System',
          href: '/system',
          roles: ['isAppAdminUser', 'isCalculationUser', 'isUserManagementUser'],
          icon: {
            status: true,
            name: 'gear',
            type: 'ph'
          }
        },
        {
          id: 5,
          disable: true,
          name: 'News',
          href: '/news',
          roles: ['isNewsUser'],
          icon: {
            status: true,
            name: 'bell',
            badge: false,
            type: 'ph'
          }
        },
        {
          disable: true,
          id: 6,
          name: 'More',
          roles: ['purchaser', 'seller', 'firmenAdmin', 'vmeAdmin'],
          icon: {
            status: true,
            name: 'dots',
            type: 'ph'
          }
        }
      ],
      currentId: localStorage.getItem('companyId')
    }
  },
  computed: {
    ...mapGetters(['getUserRole', 'getCompanies'])
  },
  methods: {
    ...mapMutations(['SET_USER_ROLE', 'SET_ACCESSTOKEN', 'SET_MS_ACCESSTOKEN', 'SET_COMPANIES']),
    isShow (par, name) {
      const userRole = this.getUserRole
      let x
      par.find(item => {
        const isHave = userRole.findIndex(role => role === item)
        if (isHave !== -1) {
          x = true
        }
      })
      return x
    },
    async onLogin (id) {
      try {
        const response = await this.$api.selectAccount(id)
        if (response.data.status === 'success') {
          try {
            const roles = response.data.data.roles
            localStorage.setItem('companyId', id)
            localStorage.setItem('token_access', response.data.data.accessToken)
            this.SET_ACCESSTOKEN(response.data.data.accessToken)
            for (const [key, value] of Object.entries(roles)) {
              if (value) {
                this.SET_USER_ROLE(key)
              }
            }
            if (this.$route.path === '/seller') {
              location.reload()
            } else {
              this.$router.push('/seller')
            }
          } catch (e) {
            console.error(e)
          }
        }
      } catch (e) {
        const isUnauthorizedError = e?.response?.status === 401 || e?.response?.status === 403
        if (isUnauthorizedError) {
          this.$msal.signOut()
        }
      }
    },
    onLogout: () => {
      localStorage.clear()
      sessionStorage.clear()
      document.cookie.split(';').forEach((cookie) => {
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/'
      })
      this.SET_ACCESSTOKEN(null)
      this.SET_MS_ACCESSTOKEN(null)
      this.SET_USER_ROLE(null)
      this.SET_COMPANIES(null)
      this.$msal.signOut()
    },
    async fetchCompanies () {
      this.showUserMenu = !this.showUserMenu
      if (this.getCompanies.length === 0) {
        this.loading = true
        const response = await this.$api.getCompanies()
        if (response.data.status === 'success') {
          this.companies = response.data.data.companies
          this.SET_COMPANIES(this.companies)
          this.loading = false
        }
      }
    }
  }
}
</script>
<style scoped>
.side-container {
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}
.side-container .line {
  border-top: 1px solid #F0F0F0;
}
.bold-text {
  font-weight: bolder;
  color: #555555;
}
.pdkt_info {
  text-align: center;
  line-height: 16px;
}
</style>
